import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { configConstants, userConstants } from "../../../../_constants";
import { stepAuthConstants } from "../../../../_constants/auth";
import { apiCaller } from "../../../../_helpers";
import { isEmpty } from "lodash";
import "./index.scss";

const AccountType = (props) => {
  const [role, setRole] = useState(props.data.role);
  const [listGrade, setListGrade] = useState([]);
  const [grade, setGrade] = useState(props.data.grade);
  const [schoolType, setSchoolType] = useState(props.data.schoolType);

  useEffect(() => {
    if (
      !listGrade.length &&
      props.step === stepAuthConstants.STEP_ACCOUNT_TYPE
    ) {
      apiCaller("/api_login/grade").then((res) => {
        if (res.status) {
          const newListGrade = res.list_grade;
          setListGrade(newListGrade);
          // if (newListGrade?.length) {
          //   setGrade(newListGrade[0].id);
          // }
        }
      });
    }
  }, [props.step]);

  const nextStep = () => {
    if (role) {
      props.setData({
        role,
        grade,
        schoolType,
      });
      props.setStep(stepAuthConstants.STEP_UPDATE_ACCOUNT);
    }
  };

  const validateParams = () => {
    return (
      role &&
      ((role == userConstants.ROLE_STUDENT && !isEmpty(grade)) ||
        (role == userConstants.ROLE_TEACHER && !isEmpty(schoolType)))
    );
  };

  const renderGradeName = (name) => {
    const split = name.match(/Khối (\d+)/);
    if (split && split.length >= 2) {
      return split[1];
    }
    return name;
  };

  return (
    <div className="sunE-form-container login-form-container register_account_type register_step">
      <div className="sunE-custom-form login-form-container_content responsive_small_screen_margin_login">
        <h2 className="title_register title text-center">ĐĂNG KÝ TÀI KHOẢN</h2>
        <h3 className="step_register_name text-center">
          {`3. Chọn trường`}
        </h3>
        {/* {!props?.org && <div className="account_content">
          <div className="account_content_title">
            <span>Tôi là:</span>
          </div>
          <div className="account_content_list">
            <div
              className={classNames(
                { blur_role: role && role !== userConstants.ROLE_STUDENT },
                "account_content_list_item student"
              )}
              onClick={() => {
                setRole(userConstants.ROLE_STUDENT);
              }}
            >
              <img src="/assets/images/auth/student.png" alt="student"></img>
            </div>
            <div
              className={classNames(
                { blur_role: role && role !== userConstants.ROLE_TEACHER },
                "account_content_list_item teacher"
              )}
              onClick={() => {
                setRole(userConstants.ROLE_TEACHER);
              }}
            >
              <img src="/assets/images/auth/teacher.png" alt="teacher"></img>
            </div>
          </div>
        </div>} */}
        <div
          className={classNames(
            { account_detail_hidden: role !== userConstants.ROLE_STUDENT },
            "account_detail_class"
          )}
        >
          {/* <div className="account_detail_class_title ">
            <span>Học khối:</span>
          </div> */}
          <div className="account_detail_class_list">
            {listGrade.map((item) => {
              return (
                <div
                  key={item.id}
                  className={classNames(
                    { active: item.id == grade },
                    "account_detail_class_list_item"
                  )}
                  onClick={() => {
                    setGrade(item.id);
                  }}
                >
                  <svg
                    width="65"
                    height="74"
                    viewBox="0 0 65 74"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M63.3739 19.6109L32.643 1.86845L1.9111 19.6116L1.91149 55.097L32.6424 72.8395L63.3738 55.0971L63.3739 19.6109Z"
                      stroke="#979797"
                      strokeWidth="2"
                    />
                  </svg>
                  <span className="class_number">
                    {renderGradeName(item.name)}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={classNames(
            { account_detail_hidden: role !== userConstants.ROLE_TEACHER },
            "account_detail_school"
          )}
        >
          <div className="account_detail_school_title">
            <span>Nơi công tác: </span>
          </div>
          <div className="account_detail_school_list">
            <div
              className={classNames(
                { active: schoolType == userConstants.SCHOOL_TYPE_PUBLIC },
                "account_detail_school_list_item"
              )}
              onClick={() => {
                setSchoolType(userConstants.SCHOOL_TYPE_PUBLIC);
              }}
            >
              <span className="school_type">
                Trường <br /> Công lập
              </span>
            </div>
            <div
              className={classNames(
                { active: schoolType == userConstants.SCHOOL_TYPE_PRIVATE },
                "account_detail_school_list_item"
              )}
              onClick={() => {
                setSchoolType(userConstants.SCHOOL_TYPE_PRIVATE);
              }}
            >
              <span className="school_type">
                Trường <br /> Dân lập
              </span>
            </div>
            <div
              className={classNames(
                { active: schoolType == userConstants.SCHOOL_TYPE_FREE },
                "account_detail_school_list_item"
              )}
              onClick={() => {
                setSchoolType(userConstants.SCHOOL_TYPE_FREE);
              }}
            >
              <span className="school_type">Tự do</span>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            {
              account_detail_hidden:
                role === userConstants.ROLE_TEACHER ||
                role === userConstants.ROLE_STUDENT,
            },
            "account_detail_placeholder"
          )}
        ></div>
        <div className="button_submit">
          <button
            onClick={nextStep}
            className={
              "btn-line-blue" + (!validateParams() ? " btn-disable" : "")
            }
            disabled={validateParams() ? false : true}
          >
            Tiếp theo
          </button>
        </div>
        <div
          className="login_text text-center no_select"
          onClick={() => {
            if (props.isLoginSocial) {
              props.backStep && props.backStep();
            } else {
              props.setStep(stepAuthConstants.STEP_CREATE_ACCOUNT);
            }
          }}
        >
          <p>Quay lại</p>
        </div>
      </div>
    </div>
  );
};

export default AccountType;
