import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import './headerTS.style.scss'

function HeaderLogoRegister({srcImg, name, color, path}) {
  return (
    <div className="logo header-ts">
      <Link to={'/'+path}>
        <img 
          src={srcImg} 
          alt={"Logo"} 
        />
        {name.split('\n').map((str, index) => <span key={index} className="flex-1 d-flex align-items-center branch-text" style={{color}}>{str}</span>)}
         
      </Link>
    </div>
  );
}

export { HeaderLogoRegister };
