import { useEffect, useState } from "react";
import InputText from "../../../../_components/Auth/InputText";
import "./index.scss";
import { stepAuthConstants } from "../../../../_constants/auth";
import $ from "jquery";

const fakeListClass =  [
  {class_nickname : "a", is_assign : true},
  {class_nickname : "b", is_assign : false},
  {class_nickname : "c", is_assign : false}
  ]
  

export default function AddClassName(props) {
  const initialClass = {
    name: '',
    isAssign: null
  }
  const role = props?.role
  const [className, setClassName] = useState('')
  const [listClass, setListClass] = useState([initialClass])
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault();
  }

  const nextStep = () => {
    const data = {}
    if(role === 'student') {
      data.class_nickname = className
    } else {
      data.list_class = listClass.map(item => item.name)
    }
    props.setData(data);
    props.setStep(stepAuthConstants.STEP_UPDATE_ACCOUNT);
  };

  const handleBack = () => {
    props.setStep(stepAuthConstants.STEP_ACCOUNT_TYPE);
  }

  const validateParam = () => {
    const isCanSubmit = !listClass.some(item => item.isAssign === true)
    if(role === 'student') {
      return !!className.length
    }
    if(listClass.length === 1 && !listClass[0].name) {
        return true
    }
    return isCanSubmit && isChecked
  }

  const handleChangeListClass = (value, index) => {
    const newList = [...listClass]
    newList[index].name = value
    newList[index].isAssign = null
    setListClass(newList)
    setIsChecked(false)
  }

  const handleRemoveClass = (index) => {
    const newList = listClass.filter((_,i) => i !== index)
    setListClass(newList)
  }

  const handleAddMoreClass = () => {
    setListClass((prev) => [...prev, initialClass])
    setIsChecked(false)
  }

  const handleCheckClass = () => {
    setIsLoading(true)
    setTimeout(() => {
      let newList = listClass.map(className => {
        const sameClass = fakeListClass.find(item => item.class_nickname === className.name)
        if(sameClass) {
          return {
            ...className,
            isAssign: sameClass.is_assign
          }
        }
        return {...className, isAssign: false}
      })
      for (let i = 0; i < newList.length; i++) {
        for (let j = i + 1; j < newList.length; j++) {
          if(newList[i].name === newList[j].name) {
            newList[j].isAssign = true
          }
        }
      }
      
      setListClass(newList)
      setIsLoading(false)
      setIsChecked(true)
    }, 1000)
  }

  const renderClassIcon = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 48 48">
        <rect width="39" height="31.2" x="4.5" y="8.4" fill="none" stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" rx="2"/>
        <rect width="31" height="23.2" x="8.5" y="12.4" fill="none" stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" rx="1"/>
        <path fill="none" stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" d="M29.68 33.41h6.62v2.19h-6.62zM25.84 19a2.6 2.6 0 0 0-3.68 0h0a2.61 2.61 0 1 0 4.46 1.86a2.54 2.54 0 0 0-.8-1.86ZM22 25.53h4a3 3 0 0 1 3 3v1.21h0h-10h0v-1.21a3 3 0 0 1 3-3m-4.58-4.06a2 2 0 0 0-2.84 0h0A2 2 0 1 0 18 22.91a2 2 0 0 0-.6-1.43Z"/>
        <path fill="none" stroke="#00B9B7" stroke-linecap="round" stroke-linejoin="round" d="M19.34 27.14a2 2 0 0 0-1.47-.65h-3.74a2 2 0 0 0-2 2v1.25H19m14.42-8.27a2 2 0 0 0-2.84 0h0A2 2 0 1 0 34 22.91a2 2 0 0 0-.6-1.43ZM29 29.74h6.86v-1.25a2 2 0 0 0-2-2h-3.73a2 2 0 0 0-1.47.65"/>
      </svg>
    );
  };

  const renderIconRemove = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24">
      <path fill="white" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"/>
    </svg>
  }
  
  const renderIconSuccess = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24">
      <path fill="white" d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z"/>
    </svg>
  )

  useEffect(() => {
    if (isLoading) {
      $(".loading").removeClass("hide");
    } else {
      $(".loading").addClass("hide");
    }
  }, [isLoading])

  return (
    <div className="add-class-form-container login-form-container register_account_type register_step">
      <div className="sunE-custom-form login-form-container_content responsive_small_screen_margin_login">
        <h2 className="title_register title text-center">ĐĂNG KÝ TÀI KHOẢN</h2>
        <h3 className="step_register_name text-center">
          {`3. ${role === 'student' ? "Chọn lớp" : "Tạo lớp"}`}
        </h3>
        <div className="add-class-content">
          <form
            className="sunE-login-app"
            onSubmit={handleSubmit}
            noValidate={true}
            autoComplete="off"
          >
            {role === 'student' ? <InputText
              value={className}
              setValue={setClassName}
              type="text"
              name="Tên lớp"
              placeholder="Nhập tên lớp"
              required={true}
              renderLabelIcon={renderClassIcon}
              autoFocus = {true}
            /> : (
              <div>
                <p className="add-class-content-label-list">Dạy các lớp:</p>
                <div className="add-class-content-list">
                  {listClass?.map((item, index) => (
                    <div key={index} className="add-class-content-item">
                      <InputText
                      key={index}
                      value={item.name}
                      setValue={(value) => handleChangeListClass(value, index)}
                      type="text"
                      name="Tên lớp"
                      placeholder="Nhập tên lớp"
                      required={true}
                      renderLabelIcon={renderClassIcon}
                      autoFocus = {true}
                      className={item?.isAssign === null ? '' : item?.isAssign ? 'warning' : 'success'}
                      />
                      {item.isAssign && listClass.length > 1 && <div className="add-class-content-icon-remove" onClick={() => handleRemoveClass(index)}>
                        {renderIconRemove()}
                      </div>}
                      {item.isAssign === false && <div className="add-class-content-icon-success">
                        {renderIconSuccess()}
                      </div>}
                    </div>
                  ))}
                  {!!listClass[listClass.length - 1]?.name && 
                  <div className="sub-action-container">
                    <button className={"btn-line-blue btn-add-more"} onClick={handleAddMoreClass}>
                      <span>Thêm lớp</span>
                    </button>
                    {!isChecked && <button className={"btn-line-blue btn-check"} onClick={handleCheckClass}>
                      <span>Kiểm tra</span>
                    </button>}
                  </div>
                  }
                </div>
              </div>
            )}
            <div className="button_submit">
              <button
                className={
                  "btn-line-blue" + (!validateParam() ? " btn-disable" : "")
                }
                disabled={!validateParam()}
                onClick={nextStep}
              >
                Tiếp theo
              </button>
            </div>
            <div className="login_text text-center" onClick={handleBack}>
              <p>
                Quay lại
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}