export const configConstants = {
    API_URL: "",
    API_KEY: "8c3a30506d9633a8b202cb5a91873efa",
    AUTH_BASIC_USER_NAME: "gek_admin",
    AUTH_BASIC_PASSWORD: "gek_admin_password",
    APP_ID_GOOGLE:
      "906644438510-eid5lsm4ndgtu0qh2lbrk31tl8r7rrt6.apps.googleusercontent.com",
    SCOPE_LOGIN_GOOGLE: "https://www.googleapis.com/auth/user.gender.read",
    APP_ID_FACEBOOK: "660393674829980",
    // BASE_URL: "http://45.119.84.189:10809/",
    BASE_URL: "https://service.sundayenglish.com/",
    EXCERCISE_URL: "https://exercise.sundayenglish.com/",
    // API_URL_SETEST: "http://45.119.84.189:10809/api",
    API_URL_SETEST: "https://service.sundayenglish.com/api",
    AUTH_URL: "https://oauth2.sundayenglish.com/",
    API_TIMEOUT: "60000",
    LOGIN_VIA_GOOLE: "GOOGLE",
    LOGIN_VIA_FACEBOOK: "FACEBOOK",
    LOGIN_VIA_APPLE: "LOGIN_VIA_APPLE",
    APP_ID_APPLE: "gk.app.sunday",
    DEFAULT_LIMIT: 10,
  };
  