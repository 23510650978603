import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const RegisterSuccess = ({handleReset}) => {
  return (
    <div className="sunE-form-container login-form-container register_step_success">
      <div className="sunE-custom-form login-form-container_content register-success-container">
        <h2 className="title_register title text-center">
          ĐĂNG KÝ THÀNH CÔNG!
        </h2>
        <div className="success_icon">
          <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.8 50.25L13.4 36.85L18.124 32.127L26.801 40.77L48.877 18.693L53.6 23.45L26.8 50.25ZM33.446 60.3H33.5C40.6055 60.2928 47.4173 57.4643 52.4378 52.4362C57.4584 47.408 60.2766 40.592 60.273 33.4865C60.2695 26.3811 57.4443 19.5678 52.4187 14.5448C47.3931 9.52172 40.5785 6.70001 33.473 6.70001C26.3676 6.70001 19.5529 9.52172 14.5273 14.5448C9.50172 19.5678 6.67658 26.3811 6.673 33.4865C6.66942 40.592 9.48774 47.408 14.5083 52.4362C19.5288 57.4643 26.3405 60.2928 33.446 60.3ZM33.5 67C26.8743 67 20.3974 65.0352 14.8884 61.3542C9.37934 57.6732 5.08561 52.4412 2.55007 46.3199C0.0145369 40.1986 -0.648904 33.4628 0.643701 26.9645C1.93631 20.4661 5.12692 14.497 9.81197 9.81192C14.497 5.12686 20.4661 1.93631 26.9645 0.643708C33.4629 -0.648896 40.1986 0.0145142 46.3199 2.55005C52.4412 5.08559 57.6732 9.37935 61.3543 14.8884C65.0353 20.3974 67 26.8743 67 33.5C67 42.3848 63.4705 50.9056 57.1881 57.1881C50.9056 63.4706 42.3848 67 33.5 67Z"
              fill="#00E2A0"
            />
          </svg>
        </div>
        <div className="success_message">
          <p>Bạn đã đăng ký tài khoản thành công!</p>
          {/* <p>Email kích hoạt đã được gửi đến địa chỉ email của bạn.</p>
          <p>Hãy kiểm tra và kích hoạt tài khoản.</p> */}
        </div>
        <div className="back_login">
          {/* <Link to="/login"> */}
            <button className={"btn-line-blue"} onClick={handleReset}>Đồng ý</button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
